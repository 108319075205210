import React, { useContext, useEffect } from "react";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Popover,
  PopoverTrigger,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import * as amplitude from "utils/amplitude/events";
import URI from "utils/urijs";
import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";
import RootContext from "app/providers/RootContext";

import usePageLayout from "app/components/shared/usePageLayout";
import { getSearch } from "app/components/shared/shared/utils";

import useAuthPermissions, {
  AuthAuthScope,
} from "app/hooks/authentication/useAuthPermissions";
import { useAuthToken } from "app/providers/AuthTokenContext";

import useGetUser from "app/queries/authentication/useGetUser";

import { Company, User } from "./UserMenu/types";
import SubItem from "./shared/SubItem";
import UserMenuList from "./UserMenu/UserMenuList";
import { getNameFromEmail } from "./UserMenu/getNameFromEmail";

export type { Company, User };

const color = (isOpen: boolean, isInAccounts: boolean) => {
  return isOpen || isInAccounts ? "sidebar-blue" : "grey-dark";
};

export default function UserMenu({
  isSidebarExpanded,
  isExpanded,
  onExpand,
}: Props) {
  const { hasWriteAccess: canManageUsers, isAuthServiceEnabled } =
    useAuthPermissions(AuthAuthScope.AuthUser);
  const { token } = useAuthToken();
  const userId = token?.userId || "";

  const { data } = useGetUser([{ userId }], {
    enabled: !!userId && isAuthServiceEnabled,
    keepPreviousData: true,
  });

  const { accounts, currentPathData } = usePageLayout({ canManageUsers });

  const isInAccounts = currentPathData.index === accounts.index;

  const {
    userFirstName: firstName,
    userEmail: email,
    currentCompany,
  } = useContext(RootContext);

  // This user name is retrieved from the database
  // If user is using authentication service, changes might take a couple of minutes until
  // changes are reflected here.
  const storedUserName = firstName || getNameFromEmail(email);

  // Even if data is present, `firstName` can be null because it is not required
  // Then we fallback to the same parse email strategy
  const authUserName = data && (data.firstName || getNameFromEmail(data.email));

  // Still fallbacks to the stored username if request is not finished, so that we don't
  // need to display a loader.
  const userName = authUserName || storedUserName;

  const { isOpen, onToggle, onClose } = useDisclosure();

  useEffect(() => {
    !isSidebarExpanded && onClose();
  }, [isSidebarExpanded, onClose]);

  return (
    <Popover
      isLazy
      placement="top-end"
      onClose={onClose}
      isOpen={isOpen && isSidebarExpanded}
    >
      <PopoverTrigger>
        <AccordionItem>
          <VStack spacing="4px" alignItems="flex-start">
            <Button
              borderRadius="4px"
              justifyContent="flex-start"
              fontWeight="regular"
              height="52px"
              minHeight="52px"
              width={isSidebarExpanded ? "200px" : "44px"}
              textTransform="capitalize"
              _hover={{ backgroundColor: "grey-pale" }}
              onClick={onToggle}
              as={AccordionButton}
              gap="4px"
              paddingInline={isSidebarExpanded ? "12px 8px" : "12px"}
              transition="300ms ease-in-out"
              transitionProperty="padding, opacity"
              color={color(isOpen, isInAccounts)}
              backgroundColor={isInAccounts ? "grey-pale" : "inherit"}
              leftIcon={
                <ChakraIcon
                  color={color(isOpen, isInAccounts)}
                  icon="person-16"
                />
              }
              rightIcon={
                isExpanded && isSidebarExpanded ? (
                  <ChakraIcon
                    color="grey-dark"
                    icon="link-specific-chevron-down-16"
                  />
                ) : undefined
              }
            >
              <Box
                w={isSidebarExpanded ? "full" : "0px"}
                overflow="hidden"
                opacity={isSidebarExpanded ? 1 : 0}
                flexGrow={1}
                textAlign="left"
              >
                <VStack alignItems="flex-start" spacing={0}>
                  <Box
                    noOfLines={1}
                    wordBreak="break-all"
                    textStyle="body2"
                    overflow="hidden"
                    color={color(isOpen, isInAccounts)}
                    w="full"
                  >
                    {userName}
                  </Box>
                  <Box
                    color="grey-dark"
                    noOfLines={1}
                    wordBreak="break-all"
                    opacity={0.64}
                    w="full"
                    overflow="hidden"
                    textStyle="caption"
                  >
                    {currentCompany.name}
                  </Box>
                </VStack>
              </Box>
            </Button>
            <AccordionPanel p={0}>
              <VStack spacing="4px">
                {accounts.items.map(
                  ({
                    label: title,
                    href,
                    isEnabled,
                    analyticsLabel,
                    isHistorySupported,
                  }) => {
                    if (!isEnabled) {
                      return null;
                    }
                    const onSubItemClick = () => {
                      amplitude.sendAmplitudeEvent({
                        eventName:
                          amplitude.AmplitudeEvents.IaSidebarMainNavigation,
                        eventProperties: {
                          label: `${accounts.analyticsLabel}:${analyticsLabel}`,
                        },
                      });
                    };
                    return (
                      <SubItem
                        label={title}
                        href={href + getSearch([])}
                        key={title}
                        isActive={URI().path().startsWith(href)}
                        isHistorySupported={isHistorySupported}
                        hasVisited
                        isSubscribed
                        onClick={onSubItemClick}
                      />
                    );
                  }
                )}
              </VStack>
            </AccordionPanel>
          </VStack>
        </AccordionItem>
      </PopoverTrigger>
      <UserMenuList
        onExpand={onExpand}
        isInAccounts={isInAccounts}
        isOpen={isOpen}
        isSidebarExpanded={isSidebarExpanded}
        onClose={onClose}
      />
    </Popover>
  );
}

type Props = {
  isSidebarExpanded: boolean;
  isExpanded: boolean;
  onExpand: () => void;
};
