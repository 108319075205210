import React, { createContext, useEffect, useState } from "react";

import { useHistory } from "react-router";

import { Collection } from "modules/collections";

export type CollectionContextType = {
  createdCollection: Collection | null;
  setCreatedCollection: React.Dispatch<React.SetStateAction<Collection | null>>;
};

export const CollectionContext = createContext<CollectionContextType>({
  createdCollection: null,
  setCreatedCollection: () => {},
});

export const CollectionContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const history = useHistory();

  const [createdCollection, setCreatedCollection] = useState<Collection | null>(
    null
  );

  useEffect(() => {
    if (
      createdCollection &&
      history.location.pathname.startsWith("/dashboard/collections/edit") &&
      !history.location.pathname.endsWith("/" + createdCollection.id)
    ) {
      setCreatedCollection(null);
    }
  }, [history.location.pathname, createdCollection, setCreatedCollection]);

  return (
    <CollectionContext.Provider
      value={{ createdCollection, setCreatedCollection }}
    >
      {children}
    </CollectionContext.Provider>
  );
};
