import { useDisclosure, useMediaQuery, useTheme } from "@chakra-ui/react";

import createPersistedState from "vendor/use-persisted-state";
import useCurrentUser from "app/hooks/useCurrentUser";
import useSessionStorage from "app/hooks/useSessionStorage";
import * as amplitude from "utils/amplitude/events";

const useSidebarState =
  createPersistedState<Record<number, boolean>>("IA_SIDEBAR_STATE");

const useSidebar = () => {
  const theme = useTheme();
  const [isDesktopView] = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);

  const user = useCurrentUser();
  const [sidebarState, setSidebarState] = useSidebarState();

  const disclosureHelper = (val: boolean) =>
    // eslint-disable-next-line no-negated-condition
    !isDesktopView
      ? null
      : setSidebarState((prev) => ({ ...prev, [user?.id]: val }));

  const sendAmplitudeSidebarEvent = (isOpen: boolean) => {
    amplitude.sendAmplitudeIdentifyEvent({
      [amplitude.AmplitudeEventProperties.IsSidebarOpen]: isOpen,
    });
    amplitude.sendAmplitudeEvent({
      eventName: amplitude.AmplitudeEvents.SidebarToggle,
      eventProperties: {
        [amplitude.AmplitudeEventProperties.IsSidebarOpen]: isOpen,
      },
    });
  };

  const { isOpen, onToggle, onOpen, onClose } = useDisclosure({
    // eslint-disable-next-line no-negated-condition
    isOpen: !isDesktopView ? undefined : sidebarState?.[user?.id] ?? true,
    onOpen: () => {
      disclosureHelper(true);
      sendAmplitudeSidebarEvent(true);
    },
    onClose: () => {
      disclosureHelper(false);
      sendAmplitudeSidebarEvent(false);
    },
  });

  const sidebarHoverHelper = useSessionStorage("sidebarHovered", false);

  return {
    isDesktopView,
    sidebarHoverHelper,
    isSidebarOpen: isOpen,
    onSidebarOpen: onOpen,
    onSidebarClose: onClose,
    onSidebarToggle: onToggle,
  };
};

export default useSidebar;
