import React, { useMemo } from "react";

import { TokenValidateResponse } from "modules/authentication/actions/tokens/validate";
import {
  AuthOperation,
  AuthAuthScope,
  AuthScope,
  AuthWebsiteScope,
} from "app/auth";
import { useAuthToken } from "app/providers/AuthTokenContext";
import { Tooltip, TooltipProps } from "app/designSystem/components/Tooltip";

import { hasPermission } from "app/auth/hasPermission";

export const EDIT_ACCESS_REQUIRED_MESSAGE =
  "Edit access required, please contact your administrator";

type UseAuthPermissionsType = {
  isAuthServiceEnabled: boolean;
  token?: TokenValidateResponse | null;
  hasReadAccess: boolean;
  hasWriteAccess: boolean;
  WriteAccessDisabledTooltip: (
    tooltipProps: React.PropsWithChildren<{
      offset?: [number, number];
      shouldWrapChildren?: boolean;
      placement?: TooltipProps["placement"];
    }>
  ) => React.JSX.Element;
};

type Scope = AuthScope | AuthScope[];

function useAuthPermissions(
  scope: Scope,
  resource?: string
): UseAuthPermissionsType {
  const { token, isAuthServiceEnabled } = useAuthToken();

  return useMemo(() => {
    if (!isAuthServiceEnabled || !token) {
      return {
        hasReadAccess: true,
        hasWriteAccess: true,
        isAuthServiceEnabled,
        token: null,
        WriteAccessDisabledTooltip: ({ children }) => <>{children}</>,
      };
    }

    const scopes = Array.isArray(scope) ? scope : [scope];

    const { hasWriteAccess, hasReadAccess } = resource
      ? getAccessForResource(scopes, resource, token)
      : getAccessForScope(scopes, token);

    return {
      hasReadAccess,
      hasWriteAccess,
      isAuthServiceEnabled,
      token,
      WriteAccessDisabledTooltip: ({
        children,
        offset,
        shouldWrapChildren = true,
        placement,
      }) => (
        <Tooltip
          shouldWrapChildren={shouldWrapChildren}
          label={hasWriteAccess ? "" : EDIT_ACCESS_REQUIRED_MESSAGE}
          offset={offset}
          placement={placement}
        >
          {children}
        </Tooltip>
      ),
    };
  }, [isAuthServiceEnabled, token, scope, resource]);
}

export default useAuthPermissions;

export {
  type UseAuthPermissionsType,
  AuthOperation,
  AuthAuthScope,
  AuthWebsiteScope,
};

function getAccessForScope(scopes: AuthScope[], token: TokenValidateResponse) {
  const getScopes = (operation: AuthOperation) =>
    token.permissions
      .filter((permission) => permission.operation.includes(operation))
      .map((permission) => permission.scope);

  const userReadScopes = getScopes(AuthOperation.Read);
  const userWriteScopes = getScopes(AuthOperation.Write);

  const hasReadAccess =
    !!scopes?.length && scopes.every((scope) => userReadScopes.includes(scope));
  const hasWriteAccess =
    !!scopes?.length &&
    scopes.every((scope) => userWriteScopes.includes(scope));

  return { hasReadAccess, hasWriteAccess };
}

function getAccessForResource(
  scopes: AuthScope[],
  resource: string,
  token: TokenValidateResponse
) {
  return {
    hasReadAccess: scopes.every((scope) =>
      hasPermission(token.permissions, scope, AuthOperation.Read, resource)
    ),
    hasWriteAccess: scopes.every((scope) =>
      hasPermission(token.permissions, scope, AuthOperation.Write, resource)
    ),
  };
}
