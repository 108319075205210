import React from "react";

import getDisplayName from "utils/getDisplayName";
import ErrorBoundary from "components/Modules/ErrorBoundary";

import { AlertFailure } from "app/designSystem/components/Alert";

export default function withError<ComponentProps extends {}>(
  Component: React.ComponentType<ComponentProps>
) {
  const ComponentWithError: React.FC<Props & ComponentProps> = ({
    error,
    errorWithChildren = false,
    ...props
  }) => {
    return error ? (
      <>
        <AlertFailure error={error} />
        {errorWithChildren && (
          <ErrorBoundary
            displayError={(error) => <AlertFailure error={error} />}
          >
            <Component {...(props as ComponentProps)} />
          </ErrorBoundary>
        )}
      </>
    ) : (
      <ErrorBoundary displayError={(error) => <AlertFailure error={error} />}>
        <Component {...(props as ComponentProps)} />
      </ErrorBoundary>
    );
  };

  ComponentWithError.displayName = `${getDisplayName(Component)}WithError`;

  return ComponentWithError;
}

type Props = {
  // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: Error | any;
  errorWithChildren?: boolean;
};
