import React, { useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import styled from "@emotion/styled";
import {
  Box,
  Text,
  Flex,
  VStack,
  HStack,
  useDisclosure,
  keyframes,
} from "@chakra-ui/react";

// @refactoring Forbid usage of `ramda` (https://constructor.slab.com/posts/deprecating-ramda-and-adopting-remeda-wlks8rn7)
// eslint-disable-next-line local-rules/no-ramda
import { sortBy, compose, toLower, prop } from "ramda";

// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme from "components/theme";
import OptionsMenu, {
  OptionLink,
  Divider,
} from "components/Modules/OptionsMenu";
import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";
import { Switch } from "app/designSystem/components";
import RootContext from "app/providers/RootContext";
import { Spinner } from "app/designSystem/components/Spinner";
import useUpdateCurrentUser from "app/queries/currentUser/useUpdateCurrentUser";
import { usePageLayoutWrapperContext } from "app/components/PageLayout/PageLayoutWrapper/PageLayoutWrapperContext";
import useFeatureToggle from "app/hooks/useFeatureToggle";
import { FeatureFlags } from "utils/generatedFeatures";

import { isAuthenticationServiceSession } from "utils/authentication";

import { Company } from "./UserMenu/Company";
import GoToNewExperienceModal from "./UserMenu/GoToNewExperienceModal";

const ripple = keyframes({
  "0%": { transform: "scale(1)", opacity: 0.65 },
  "100%": { transform: "scale(2.5)", opacity: 0.02 },
});

function UserMenu({ user, currentCompany, companies }: Props) {
  const sortByName = sortBy(compose(toLower, prop("name")));

  const {
    isOpen: isGoToNewExperienceModalOpen,
    onOpen: onGoToNewExperienceModalOpen,
    onClose: onGoToNewExperienceModalClose,
  } = useDisclosure();

  const { setIsLayoutUpdating } = usePageLayoutWrapperContext();

  const { setIsIaResponsePageLayoutActive } = useContext(RootContext);

  const { mutate: updateCurrentUser } = useUpdateCurrentUser();

  const isResponsivePageLayoutFlipperFlagActive = useFeatureToggle(
    FeatureFlags.EnableIaResponsivePageLayout
  );
  const isNewPageLayoutByDefaultEnabled = useFeatureToggle(
    FeatureFlags.EnableIaResponsivePageLayoutByDefault
  );

  const isUsingNewAuthenticationService = isAuthenticationServiceSession();

  const onResponsiveLayoutChange = () => {
    setIsLayoutUpdating(true);
    updateCurrentUser(
      { isIaResponsivePageLayoutActive: true },
      {
        onSuccess: (currentUser) => {
          setTimeout(() => {
            setIsLayoutUpdating(false);
            setIsIaResponsePageLayoutActive(
              currentUser.isIaResponsivePageLayoutActive
            );
          }, 1500);
        },
      }
    );
  };

  const indicator = {
    content: '""',
    position: "absolute",
    left: "-2px",
    top: "-2px",
    height: "8px",
    aspectRatio: 1,
    borderRadius: "50%",
    background: "linear-gradient(180deg, #0B63C2 0%, #00B8D9 100%)",
  };

  return (
    <>
      <GoToNewExperienceModal
        onSubmit={onResponsiveLayoutChange}
        isOpen={isGoToNewExperienceModalOpen}
        onClose={onGoToNewExperienceModalClose}
      />
      <UserDropdown alignRight>
        <Toggle>
          <Flex
            alignItems="center"
            textAlign="left"
            marginRight="6px"
            color="grey-dark"
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius="50%"
              width="30px"
              height="30px"
              marginRight="12px"
              border="solid 2px"
              borderColor="grey-light-green"
              color="grey"
              {...(isResponsivePageLayoutFlipperFlagActive
                ? {
                    position: "relative",
                    _before: indicator,
                    _after: {
                      ...indicator,
                      animation: `${ripple} 1200ms infinite ease-in`,
                    },
                  }
                : {})}
            >
              <ChakraIcon icon="account-16" />
            </Flex>

            <Box>
              <Text
                margin="0"
                textTransform="capitalize"
                textStyle="body2"
                lineHeight="normal"
              >
                {user.firstName || (user.email || "").split("@")[0]}
              </Text>
              <Text height="15px" margin="0" opacity="0.64" textStyle="caption">
                {currentCompany.name}
              </Text>
            </Box>
          </Flex>

          <ChakraIcon icon="arrow-drop-down-16" color="grey-dark" />
        </Toggle>

        <Menu>
          <OptionsMenu>
            {!isNewPageLayoutByDefaultEnabled &&
              isResponsivePageLayoutFlipperFlagActive && (
                <Box bgColor="blue-dark" padding="20px">
                  <VStack
                    alignItems="flex-start"
                    textTransform="none"
                    spacing="2px"
                  >
                    <HStack justifyContent="space-between" w="full">
                      <Box fontWeight="bold" color="white" textStyle="body2">
                        Sidebar navigation
                      </Box>
                      {isGoToNewExperienceModalOpen ? (
                        <Spinner size="sm" color="white" />
                      ) : (
                        <Switch
                          isChecked={false}
                          onChange={onGoToNewExperienceModalOpen}
                        />
                      )}
                    </HStack>
                    <Box textStyle="body3" color="grey-light-blue">
                      Try the new experience and give feedback.
                    </Box>
                  </VStack>
                </Box>
              )}
            {companies.length > 1 &&
              sortByName(companies).map((company) => (
                <React.Fragment key={company.id}>
                  <Company
                    company={company}
                    selected={company.id === currentCompany.id}
                  />
                  <Divider />
                </React.Fragment>
              ))}
            {isUsingNewAuthenticationService ? (
              <OptionLink href="/dashboard/accounts_v2/profile">
                Account
              </OptionLink>
            ) : (
              <OptionLink href="/dashboard/accounts/profile">
                Account
              </OptionLink>
            )}

            <OptionLink href="/users/sign_out">Log out</OptionLink>
          </OptionsMenu>
        </Menu>
      </UserDropdown>
    </>
  );
}

type Props = {
  user: {
    firstName: string;
    email: string;
  };
  currentCompany: { id: number; name: string };
  companies: { id: number; name: string }[];
};

export default UserMenu;

const UserDropdown = styled(Dropdown)`
  position: relative;
  padding: 0 15px 0 30px;
  font-size: 15px;
  text-transform: none;
  letter-spacing: normal;
  height: 100%;
  align-items: center;
  border-left: 2px solid ${theme.colors.backgroundGray};
  z-index: 10;

  &.dropdown .dropdown-menu {
    display: none;
    min-width: 160px;
    padding: 0;
    border: 0;
  }

  &.dropdown.show .dropdown-menu {
    display: block;
  }
`;

const Toggle = styled(Dropdown.Toggle)`
  width: 100%;
  padding: 12px 0px;
  font-size: 14px;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;

  &.btn {
    text-align: left;
    background: transparent;
    border: none;
  }
`;

const Menu = styled(Dropdown.Menu)`
  /*
    This should be the default from Bootstrap, but it's likely we're either on
    a different version than the one react-bootstrap expects, or we have just
    changed it at some point. We'll move away from Boostrap's JS anyway, so
    this works as a fix for now to make sure the dropdown is wide enough for its
    contents
  */
  right: auto;
  width: 360px;
`;
