import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { css, keyframes } from "@emotion/react";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme from "components/theme";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import Icon from "components/Modules/Icon";
import colors from "app/designSystem/theme/foundations/colors";

export type Kind =
  | "default"
  | "primary"
  | "info"
  | "secondary"
  | "link"
  | "body-link"
  | "danger"
  | "action-success"
  | "action-danger"
  | "text"
  | "block"
  | "action-success-new"
  | "action";

export const sizes = {
  small: css`
    padding: 8px 30px;
  `,
  medium: css`
    height: 46px;
    padding: 0 30px;
  `,
  default: css`
    padding: 15.5px 30px;
  `,
};

const kind = css`
  box-shadow: none;
  color: ${theme.colors.backgroundWhite};

  :focus:not(:disabled),
  :hover:not(:disabled) {
    color: ${theme.colors.backgroundWhite};
  }

  & ${Icon} {
    margin: -8px 8px -8px -16px;
  }

  :disabled {
    background-color: ${theme.colors.backgroundPrimaryDisabled};
    color: ${theme.colors.textGrayDarkDisabled};
  }
`;

const link = css`
  ${kind};
  padding: 0;
  box-shadow: none;
  letter-spacing: normal;
  text-transform: none;
  color: ${theme.colors.headerBlack};
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  border-radius: 0;
  vertical-align: initial;

  :hover:not(:disabled) {
    text-decoration: underline;
  }

  :focus:not(:disabled) {
    background: ${theme.colors.neutralGreenishgrey};
  }

  :hover:not(:disabled),
  :focus:not(:disabled) {
    outline: none;
    color: ${theme.colors.headerBlack};
  }

  :disabled {
    color: ${theme.colors.neutralDisabledGrey};
    background-color: transparent;
  }
`;

const action = css`
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: ${theme.colors.backgroundWhite};

  :disabled {
    cursor: initial;
  }
`;

const text = css`
  background: transparent;
  width: auto;
  margin: 0;
  padding: 0;
  display: inline;
  color: inherit;
  border: none;
  text-transform: none;
  box-shadow: none;
  font-weight: normal;
  letter-spacing: normal;
  text-align: left;
  border-radius: 0;
  font-size: inherit;

  :focus-visible {
    background-color: var(--neutral-greenishligthgrey);
    outline: none;
  }
`;

export const kinds = {
  default: css``,
  primary: css`
    ${kind};
    background-color: ${theme.colors.backgroundPrimary};

    :hover:not(:disabled) {
      background-color: ${theme.colors.primaryDarkGreen};
    }

    :focus:not(:disabled) {
      outline: none;
      background-color: ${theme.colors.primaryDarkGreen};
      box-shadow: inset 0 0 0 2px ${theme.colors.darkHighlight};
    }

    :disabled {
      background-color: ${colors.gray["50"]};
      border: 2px solid ${colors.gray["100"]};
    }
  `,
  info: css`
    ${kind};
    background-color: ${theme.colors.backgroundInfo};

    :hover:not(:disabled) {
      background-color: ${theme.colors.secondaryBlueDark};
    }

    :focus:not(:disabled) {
      outline: none;
      background-color: ${theme.colors.secondaryBlueDark};
      box-shadow: inset 0 0 0 2px ${theme.colors.backgroundInfo};
      color: ${theme.colors.backgroundWhite};
    }
  `,
  secondary: css`
    ${kind};
    color: ${theme.colors.textGray};
    background-color: ${theme.colors.backgroundWhite};
    box-shadow: inset 0 0 0 2px ${theme.colors.borderGrayLight};

    :focus:not(:disabled),
    :hover:not(:disabled) {
      color: ${theme.colors.textGray};
      background-color: ${theme.colors.borderGrayLight};
    }

    :focus:not(:disabled) {
      outline: none;
      box-shadow: inset 0 0 0 2px inherit;
    }

    :disabled {
      box-shadow: none;
    }
  `,
  link: link,
  ["body-link"]: css`
    ${link};
    color: ${theme.colors.darkHighlight};

    :hover {
      color: ${theme.colors.darkHighlight};
    }
  `,
  danger: css`
    ${kind};
    background-color: ${theme.colors.danger};

    :hover:not(:disabled) {
      background-color: ${theme.colors.dangerBackgroundDark};
    }

    :focus:not(:disabled) {
      outline: none;
      background-color: ${theme.colors.dangerBackgroundDark};
      box-shadow: inset 0 0 0 2px ${theme.colors.neutralDisabledGrey};
    }
  `,
  ["action"]: css`
    ${action};
  `,
  ["action-success"]: css`
    ${action};

    :hover:not(:disabled),
    :focus:not(:disabled) {
      box-shadow: inset 0 0 0 2px ${theme.colors.backgroundGrayHover};
      background: ${theme.colors.backgroundGrayHover};
      color: ${theme.colors.white};
      outline: none;
    }

    :focus:not(:disabled) {
      box-shadow: inset 0 0 0 2px ${theme.colors.backgroundGrayHoverDark};
    }

    :disabled {
      background: ${theme.colors.disabled};
      box-shadow: inset 0 0 0 2px ${theme.colors.disabled};
    }
  `,
  ["action-danger"]: css`
    ${action};

    :hover:not(:disabled),
    :focus:not(:disabled) {
      box-shadow: inset 0 0 0 2px ${theme.colors.danger};
      background: ${theme.colors.danger};
      color: ${theme.colors.white};
      outline: none;
    }

    :focus:not(:disabled) {
      box-shadow: inset 0 0 0 2px ${theme.colors.dangerDark};
    }

    :disabled {
      background-color: ${colors.gray["50"]};
      border-color: ${colors.gray["100"]};
    }
  `,
  ["action-success-new"]: css`
    ${action};

    :hover:not(:disabled),
    :focus:not(:disabled) {
      box-shadow: inset 0 0 0 2px ${theme.colors.backgroundGrayHover};
      background: ${theme.colors.backgroundGrayHover};
      color: ${theme.colors.white};
      outline: none;
    }

    :focus:not(:disabled) {
      box-shadow: inset 0 0 0 2px ${theme.colors.backgroundGrayHoverDark};
    }

    :disabled {
      color: rgba(80, 92, 117);
      opacity: 0.38;
      box-shadow: inset 0 0 0 2px ${theme.colors.disabled};
    }
  `,
  text: text,
  block: css`
    ${text};
    display: block;
    width: 100%;
  `,
};

export const Content = styled("span", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "loading",
})<{ loading: boolean }>`
  opacity: ${({ loading }) => (loading ? "0" : "1")};
  display: flex;
  align-items: center;
`;

const SpinnerAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerElement = styled.div`
  background-size: 16px;
  background-image: url(${require("../Images/spinner.svg")});
  width: 16px;
  height: 16px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
  animation: ${SpinnerAnimation} 2s linear infinite;
`;

export const ButtonElement = styled.button<{
  size: keyof typeof sizes;
  kind: keyof typeof kinds;
}>`
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: none;
  border-radius: 9999px;
  font-family: ${theme.fonts.text};
  font-size: 12px;
  color: ${theme.colors.textGrayDark};
  text-transform: uppercase;

  // box-shadow instead of border to make it an "inset" border and have the
  // same size as buttons without a border
  box-shadow: inset 0 0 0 2px ${theme.colors.borderGrayLight};
  letter-spacing: 1px;
  position: relative;
  font-weight: bold;
  vertical-align: middle;

  :hover {
    text-decoration: none;
    cursor: pointer;
  }

  :hover:disabled {
    cursor: not-allowed;
  }

  ${({ size }) => sizes[size]}
  ${({ kind }) => kinds[kind]}

  button + &, a + &, span.wrapper & {
    margin-left: 16px;
  }
`;
