import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { textStyles } from "../foundations/typography";

const isNewDesignSystem =
  // eslint-disable-next-line no-negated-condition
  typeof window !== "undefined"
    ? isFlagEnabled(FeatureFlags.EnableNewDesignSystem)
    : false;

const variants = {
  primary: {
    bg: "old-green",
    color: "white",
    _hover: {
      bg: "green-dark",
      color: "white",
    },
    _active: { bg: "green-dark", borderColor: "success" },
    _focus: { bg: "green-dark", borderColor: "success" },
    svg: {
      color: "green-dark",
    },
  },
  ghost: {
    bg: "white",
    color: "grey-dark",
    borderColor: "grey-light-blue",
    _hover: {
      bg: "grey-light-blue",
      color: "grey-dark",
    },
    _active: { bg: "grey-light-blue", borderColor: "grey-dark" },
    _focus: { bg: "grey-light-blue", borderColor: "grey-dark" },
    svg: {
      me: "1px",
    },
  },
  danger: {
    bg: "danger",

    color: "white",
    _hover: {
      bg: "danger-dark",
      color: "white",
    },
    _active: { bg: "danger-dark", borderColor: "danger-dark-2" },
    _focus: { bg: "danger-dark", borderColor: "danger-dark-2" },
  },
  secondary: {
    bg: "old-blue",
    color: "white",
    _hover: {
      bg: "blue-dark",
      color: "white",
    },
    _active: { bg: "blue-dark", borderColor: "old-blue", color: "white" },
    _focus: { bg: "blue-dark", borderColor: "old-blue" },
    svg: {
      color: "old-blue",
    },
  },
  link: {
    ...textStyles.body2,
    bg: "transparent",
    color: "black",
    borderRadius: "none",
    pt: "0",
    pb: "0",
    h: "auto",
    minHeight: "auto",
    textTransform: "none",
    letterSpacing: "normal",
    fontWeight: "normal",
    _active: { bg: "transparent", textDecoration: "underline" },
    _focus: { bg: "transparent", textDecoration: "underline" },
    _disabled: {
      bg: "transparent",
      borderColor: "transparent",
      cursor: "not-allowed",
    },
    _hover: {
      _disabled: {
        bg: "transparent",
        cursor: "not-allowed",
        borderColor: "transparent",
      },
    },
  },
};

const baseStyle = {
  borderRadius: "22px",
  height: "auto",
  width: "auto",
  border: "2px solid transparent",
  maxWidth: "100%",
  whiteSpace: "normal",
  cursor: "pointer",
  fontWeight: "bold",
  _focus: {
    boxShadow: "none",
    outline: "none",
  },
  _active: {
    boxShadow: "none",
    outline: "none",
  },
  _disabled: {
    cursor: "not-allowed",
    opacity: isNewDesignSystem ? "1" : "0.4",
    bg: isNewDesignSystem ? "gray.50" : "grey-disabled",
    color: isNewDesignSystem ? "gray.400" : "grey",
    border: isNewDesignSystem && "2px solid",
    borderColor: isNewDesignSystem && "gray.100",
    svg: {
      color: isNewDesignSystem ? "gray.400" : "grey",
    },
  },
  _hover: {
    _disabled: {
      cursor: "not-allowed",
      bg: isNewDesignSystem ? "gray.50" : "grey-disabled",
      color: isNewDesignSystem ? "gray.400" : "grey",
      border: isNewDesignSystem && "2px solid",
      borderColor: isNewDesignSystem ? "gray.100" : "transparent",
      svg: {
        color: isNewDesignSystem ? "gray.400" : "grey",
      },
    },
  },
};

const sizes = {
  lg: {
    pt: "12px",
    pb: "12px",
    pe: "26px",
    ps: "26px",
    h: "auto",
    minHeight: "44px",
    ...textStyles.buttonLabel,
  },
  sm: {
    pt: "6px",
    pb: "6px",
    pe: "14px",
    ps: "14px",
    h: "auto",
    minHeight: "32px",
    ...textStyles.buttonLabel,
  },
};

export default {
  variants,
  baseStyle,
  sizes,
};
