import React from "react";
import styled from "@emotion/styled";

import BaseLink from "components/Modules/Link";
// Disabling linter for next line to deprecate the module
// TODO: Replace this import with respective non-deprecated module
// eslint-disable-next-line no-restricted-imports
import theme from "components/theme";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Icon from "./Icon";

export const Chevron = styled(Icon)`
  vertical-align: middle;
  position: relative;
  margin-left: 11px;
  top: -1px;
`;

function Link({ children, ...props }: Props) {
  return (
    <BaseLink {...props}>
      {children}
      <Chevron icon="chevron-secondary-cropped" />
    </BaseLink>
  );
}

type Props = {
  children: React.ReactNode;
} & React.ComponentProps<typeof BaseLink>;

const LinkWithChevron = styled(Link)`
  color: ${theme.colors.headerBlack};

  &:hover {
    color: ${theme.colors.headerBlack};
  }
`;

export default LinkWithChevron;
